import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import './Machine.css'

const lightboxOptions = {
    buttons: {
        showDownloadButton: false,
    },
    caption: {
        showCaption: true,
    },
    thumbnails: {
        showThumbnails: false
    }
}

function MachineImage(props) {
    return (
        <div className="machineImageContainer">
            <div className="machineImageFrame">
                <img src={"/images/gallery/" + props.src} className="machineImage" alt={props.children} />
                <div className="machineImageText">{props.children}</div>
            </div>
        </div>
    )
}

function MachineImageWide(props) {
    return (
        <div className="machineImageContainer machineImageContainerWide">
            <div className="machineImageFrame">
                <img src={"/images/gallery/" + props.src} className="machineImage" alt={props.children} />
                <div className="machineImageText">{props.children}</div>
            </div>
        </div>
    )
}

function Machine() {
    return (
        <div className="Machine">
            <div className="machineHero">
                <div className="machineTitle">The Machine</div>
                <div className="machineText machineHeroText">
                    <p>Building a pinball machine from scratch is no easy feat. It requires
                    time, patience, and a willingness to learn software programming,
                    electronics, UX design, graphic design, and woodworking. 
                    </p>
                    <p>There are
                    many websites, forums, and social groups dedicated to the topic, so
                    this page is not an instruction manual. It instead is a partial
                    documentation of Mass Effect 2: the Pinball Game's journey from
                    concept to completion.
                    </p>
                </div>
            </div>
            <SRLWrapper options={lightboxOptions}>
            <div className="machineBlock">
                {/* -- Row 1 -- */}
                <div className="machineText">
                    <p>
                        Mass Effect 2: the Pinball Game was built by hand in a 
                        one-bedroom apartment using only a router, circular saw,
                        cordless drill, carpenter's square, and tape measure.
                    </p>
                    <p>
                        It was originally intended to be a retheme of Stern's&nbsp;
                        <i>Game of Thrones</i> pinball machine and was prototyped
                        running on a <i>GoT</i> machine. Ultimately I decided not to 
                        destroy a perfectly good pinball game and instead built a new one from 
                        scratch. The <i>Mass Effect 2</i> playfield
                        still carries the general layout of that excellent and underrated
                        title, with some significant modifications.
                    </p>
                </div>
                <MachineImage src="MA_0827.png">Playfield Mk1 with ramps, return lanes, and some switches</MachineImage>
                <MachineImage src="MA_0074.png">Painting and assembling the cabinet, backbox, and LCD panel</MachineImage>
                
                {/* -- Row 2 -- */}
                <MachineImage src="MA_0050.png">Playfield Mk2 with plastic inserts for lighting</MachineImage>
                <MachineImage src="MA_0051.png">Playfield Mk2 gets mounted in the cabinet for the first time</MachineImage>
                <div className="machineText">
                    <p>
                        The first iteration of the playfield was a template for 
                        fabricating the steel guides, wiring harnesses, and 
                        verifying functionality&mdash;but mistakes were made 
                        during the cutting process and due to some extra holes,
                        the playfield could not be used to actually play a game on.
                    </p>
                    <p>
                        The second iteration of the playfield added the basic
                        lights necessary to play the game and a first draft of
                        the playfield artwork (assembled from game captures,
                        concept art, and NASA imagery), and was suitable for play.
                    </p>
                </div>
            
                {/* -- Row 3 -- */}
                <MachineImage src="MA_0042.png">First run of the wiring harness for power and switches on Mk2, using crimp connectors for repeated disassembly and reassembly</MachineImage>
                <MachineImage src="MA_0210.png">Playfield Mk2 with artwork and plastics. Note the empty center of the field, where many lights were added on Mk3</MachineImage>
                <MachineImageWide src="MA_0204.png">Artwork is applied to the cabinet via large vinyl prints and coated to protect from stratches</MachineImageWide>
            
                {/* -- Row 4 -- */}
                <MachineImage src="MA_0271.png">Carbon transfer paper was used to draw the layout onto the wood that would become Playfield Mk3.</MachineImage>
                <MachineImage src="MA_0274.png">Playfield Mk3, like its predecessors, was cut freehand with a compact router.</MachineImage>
                <div className="machineText">
                    <p>
                        The third (and current) playfield includes dozens more
                        lights, including a progress ladder, flashers, available
                        class power abilities, and general illumination. Like
                        the second iteration, it has a transparent vinyl adhesive
                        for the artwork, and a clearcoat layer to protect it.
                    </p>
                    <p>
                        To support the larger number of LEDs, responsibility 
                        for lighting was moved from the FAST onboard controller 
                        to a dedicated FadeCandy controller.
                    </p>
                </div>
                <MachineImageWide src="MA_0417.png">
                    The custom "airlock" lane that locks balls between the ramps and provides a captive ball target.
                    This replaces the Game of Thrones dragon kickback lane and separates the ball lock from the
                    mission select hold on the left ramp.</MachineImageWide>
                {/* -- Row 5 -- */}
                <div className="machineText">
                    <p>
                        The machine is powered by an UP Core Plus (Atom x5, 4GB RAM) 
                        single-board computer running Arch Linux or Debian. The playfield switches
                        and coils are controlled by a network of FAST Pinball boards,
                        with lighting managed by a FadeCandy.
                    </p>
                    <p>
                        The game software is written using the Mission Pinball Framework (MPF)
                        and is over 30,000 lines of code: about 90% YAML configuration files 
                        for MPF, the rest Python code for custom behaviors. The split LCD
                        display uses images and video captured from the original game and is designed
                        to communicate clearly and minimize distraction.
                    </p>
                </div>
                <MachineImage src="MA_0301.png">Steel wire guides installed on Mk3. The post placements were slightly different than Mk2, so new guides had to be made.</MachineImage>
                <MachineImage src="MA_0286.png">Plastics were printed with custom artwork and hand-cut with the same router as the playfield.</MachineImage>

                {/* -- Row 6 -- */}
                <MachineImage src="MA_0418.png">Final wiring harness for Mk3, including switches, lights, 12V and 5V power, and the FAST network loop.</MachineImage>
                <MachineImage src="MA_0324.png">Lighting test of assembled Mk3 in cabinet with backbox illumination</MachineImage>
            </div>
            <div className="machineBlock machineBottom">
                <div className="machineText machineSmall">
                    <p>
                        This project has been an absolute joy to work on, from the earliest ideation and 
                        electronics testing to woodworking and graphic design to sharing with friends and fans.
                        Thanks to the pinball homebrew community and expo organizers, and of course thanks to
                        BioWare for creating the amazing world of Mass Effect and the epic journey of Commander Shepard.
                    </p>
                </div>
                <div className="machineText machineLinks">
                    <div className="machineLinkContainer">
                        <b>Useful links:</b>
                        <p><a className="machineLink" href="https://github.com/avanwinkle/masseffect2" target="_meplink">Mass Effect 2: the Pinball Game<br/>[Source Code]</a></p>
                        <p><a className="machineLink" href="https://missionpinball.org/" target="_meplink">Mission Pinball Framework</a></p>
                        <p><a className="machineLink" href="https://fastpinball.com/" target="_meplink">FAST Pinball Controllers</a></p>
                        <p><a className="machineLink" href="https://up-shop.org/" target="_meplink">UP Single-Board Computers</a></p>
                        <p><a className="machineLink" href="https://groups.google.com/g/mpf-users" target="_meplink">MPF Support Forum</a></p>
                    </div>
                </div>
                
            </div>
            </SRLWrapper>
        </div>
    )
}

export default Machine;
