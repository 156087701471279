import React from 'react';
import './Modes.css';
/*
function ModeEntry(props) {
    const { color, description, name, summary, image } = props.modeData;
    return (
        <div className="modeEntry" style={{ backgroundColor: "#" + color }}>
            <div className="modeEntryContent">
            <div className="modeEntryImageContainer">
                <img className="modeEntryImage" src={"/images/modes/" + image} />
            </div>
            <div className="modeEntryTextContainer">
                <div className="modeEntryTitle">{name}</div>
                <div className="modeEntryText modeEntrySummary">{summary}</div>
                <div className="modeEntryText">{description}</div>
                { props.statuses && props.statuses.map(status => {
                    const stat = props.modeData.statuses[status];
                    return (
                        <div className="modeEntryStatus">
                            <div className="modeEntryStatusTitle">{stat.name}</div>
                            <div className="modeEntryStatusText">{stat.instructions}</div>
                        </div>
                    );
                })}
            </div>
            </div>
        </div>
    )
}
*/
function Modes(props) {
    return (
        <div className="Modes">
            <div className="modeHero">
                <div className="modeHeroContent">
                    <div className="modeHeroTitle">
                        Game Modes
                    </div>
                    <div className="modeHeroText">
                        The gameplay of <i>Mass Effect 2: the Pinball Game</i> is dedicated to
                        recreating the experience of Commander Shepard's battle with the
                        collectors. Every mode is designed to evoke the same excitement
                        and tension as the video game&mdash;not only through sound and 
                        lights, but with gameplay objectives specially tailored to the 
                        mission at hand.
                    </div>
                    <div className="modeHeroText">
                        <i>Mass Effect 2: the Pinball Game</i> is built on the tenets of Pinball
                        Theory and emphasizes the "direct" style of gameplay in every mode.
                        The carefully crafted ruleset brings the story to life with a
                        visceral intensity unlike any other pinball experience.
                    </div>
                    <div className="modeHeroText">
                        <a className="modeLink" href="#recruitment">Recruitment Missions</a>
                        <a className="modeLink" href="#collector">Collector Missions</a>
                        <a className="modeLink" href="#suicidemission">the Suicide Mission</a>
                        <a className="modeLink" href="#dlc">DLC Missions</a>
                        <a className="modeLink" href="#other">Additional Mechanics</a>
                    </div>
                </div>
            </div>
            <a aria-label="recruitment missions" name="recruitment"></a>
            <div className="modeSectionTitle">Recruitment Missions</div>
            <div className="modeSection">
                <div className="modeSectionIntro">
                    The core gameplay of <i>Mass Effect 2</i> revolves around the recruitment of
                    squadmates. Each mission has a unique ruleset design to mimic Shepard's actions
                    as directly as possible, and uses lighting, sound effects, voice callouts, and
                    music to recreate the mission from the game.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/gr_default.png" />
                    </div>
                    At the start of the game, the five main lanes are lit with the 
                    first five recruits (Grunt, Jack, Garrus, Mordin, and Kasumi). Hit one of
                    their lanes three times to unlock their mission, and start the mission from
                    the galaxy map (left ramp). After recruiting a squadmate, another will be
                    available on the same lane (Zaeed, Legion, Samara, Thane, and Tali).
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/ja_default.png" />
                    </div>
                </div>
                <div className="modeSectionListContainer">
                    <div className="modeSectionList">
                        <div className="modeSectionListHeader">Featuring:</div>
                        <div className="modeSectionListTitle">The Warlord: Defeat the Tank-Bred</div>
                        {/* <div className="modeSectionListDetail">Hit each lit shot to defeat a tank-bred Krogan and reset the timer. Finish all shots to complete the mission.</div> */}
                        <div className="modeSectionListTitle">The Convict: Escape Purgatory Prison</div>
                        {/* <div className="modeSectionListDetail">Hit any lit shot to unlock a prison door, then hit the right bank to advance through the prison. Advance three times to defeat the warden and complete the mission.</div> */}
                        <div className="modeSectionListTitle">Archangel: Siege on Omega</div>
                        {/* <div className="modeSectionListDetail">Take out each of the three lit shots to close the shutters and stop the Blood Pack's assault. When all shutters are closed, get upstairs and protect Archangel to complete the mission.</div> */}
                        <div className="modeSectionListTitle">The Thief: Breaking and Entering</div>
                        {/* <div className="modeSectionListDetail">Hit the un-alarmed shots to collect a voiceprint, DNA sample, and password, then one more to enter the vault. Each shot hit turns into an alarm, and three alarm trips will end the mission.</div> */}
                        <div className="modeSectionListTitle">The Professor: Cure the Plague</div>
                        {/* <div className="modeSectionListDetail">Hit the spinner and right orbit to place the cure. Get to both fans to disburse the cure and complete the mission.</div> */}
                        <div className="modeSectionListTitle">The Mercenary: Blue Suns Refinery</div>
                        {/* <div className="modeSectionListDetail">Choose the blue shot to rescue the factory workers, or the red shot to pursue Vido, and then either orbit to advance the mission. Catch up to Vido to complete the mission.</div> */}
                        <div className="modeSectionListTitle">Legion: Hack the Heretics</div>
                        {/* <div className="modeSectionListDetail">Keep the heretic geth at bay in this tower-defense mode. Each shot progresses the hack, but don't let them reach your position!</div> */}
                        <div className="modeSectionListTitle">The Justicar: On the Trail of Morinth</div>
                        {/* <div className="modeSectionListDetail">Hit the targets to damage the gunship's armor and light lane shots to damage it. Hit five lane shots to destroy the gunship and complete the mission.</div> */}
                        <div className="modeSectionListTitle">The Assassin: Assault Dantius Towers</div>
                        {/* <div className="modeSectionListDetail">Hit the ramps repeatedly to ascend the tower. Once high enough, hit either of the orbits to cross the bridge and complete the mission.</div> */}
                        <div className="modeSectionListTitle">The Quarian: Geth on Haestrom</div>
                        {/* <div className="modeSectionListDetail">Hit the Colossus to deplete its barrier and armor, but hurry! The Colossus will self-repair after a few seconds, requiring additional hits to defeat.</div> */}
                    </div>
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/ga_default.png" />
                    </div>
                </div>
            </div>
            <a aria-label="collector missions" name="collector"></a>
            <div className="modeSectionTitle">Collector Missions</div>
            <div className="modeSection">
                <div className="modeSectionIntro">
                    As the squad grows, missions unlock that require Shepard to face the Collectors
                    head-on. These missions will be automatically triggered when you enter the
                    Mass Relay.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cs_default.png" />
                    </div>
                    The Collector missions are fast-paced multiballs and mini-wizard modes that challenge
                    even the toughest players. Choose your squadmates wisely and upgrade your weapons and
                    armor for the best chance at survival.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/dr_default.png" />
                    </div>
                </div>
                <div className="modeSectionListContainer">
                <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_praetorian.png" />
                    </div>
                    <div className="modeSectionList">
                        <div className="modeSectionListHeader">Featuring:</div>
                        <div className="modeSectionListTitle">The Collector Ship</div>
                        <div className="modeSectionListTitleSub">Survive the Ambush</div>
                        <div className="modeSectionListTitleSub">Escape the Horde</div>
                        <div className="modeSectionListTitleSub">Defeat the Praetorian</div>
                        <div className="modeSectionListTitle">The Derelict Reaper: Destroy the Core</div>
                        <div className="modeSectionListTitle">The Normandy: Escape the Attack</div>
                    </div>
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/na_default.png" />
                    </div>
                </div>
            </div>
            <a name="suicidemission"></a>
                <div className="modeSectionTitle">the Suicide Mission</div>
            <div className="modeSection">
                <div className="modeSectionIntro">
                    When the Reaper IFF is installed in the Normandy, it's time to take the fight to the Collectors.
                    Finish any recruitments, side-missions, and upgrades first because once you pass the Omega 4 Relay,
                    there's no coming back.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_suicide_start.png" />
                    </div>
                    The Suicide Mission brings new meaning to the phrase "wizard mode", and is the pinnacle of
                    how pinball design can faithfully recreate the Mass Effect 2 experience&mdash;from the frantic
                    scramble through the debris field, to the tension and danger of the long walk through seeker
                    swarms, to the rapid-fire battle against the human reaper.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_suicide_longwalk.png" />
                    </div>
                    Fun fact: over 20% of all the code written for <i>Mass Effect 2: the Pinball Game</i> is for the Suicide Mission
                    alone.
                </div>
                <div className="modeSectionListContainer">
                <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_suicide_infiltration.png" />
                    </div>
                    <div className="modeSectionList">
                        <div className="modeSectionListHeader">Featuring:</div>
                        <div className="modeSectionListTitle">The Omega 4 Relay</div>
                        <div className="modeSectionListTitle">Infiltration</div>
                        <div className="modeSectionListTitle">The Long Walk</div>
                        <div className="modeSectionListTitle">The Human Reaper</div>
                        <div className="modeSectionListTitle">The End Run</div>
                    </div>
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_suicide_humanreaper.png" />
                    </div>
                </div>
            </div>
            <a name="dlc"></a>
                <div className="modeSectionTitle">DLC Missions</div>
            <div className="modeSection">
                <div className="modeSectionIntro">
                    Explore the galaxy and unlock additional missions during
                     gameplay. <b>Overlord</b> and <b>Arrival</b> bring unique 
                     challenges to the multiball format, 
                    while <b>Firewalker</b> and <b>N7 Assignments</b> offer a fun break from recruitment missions.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/ov_default.png" />
                    </div>
                    <b>Lair of the Shadow Broker</b> is presented as a series of four unlockable modes that relive the full
                    story of Liara's quest for vengance, including a chase mode, a tower defense mode, and a tense 
                    two-ball multiball battle against the Broker himself.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/sb_default.png" />
                    </div>
                </div>
                <div className="modeSectionListContainer">
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_arrival.png" />
                    </div>
                    <div className="modeSectionList">
                        <div className="modeSectionListHeader">Featuring:</div>
                        <div className="modeSectionListTitle">Lair of the Shadow Broker</div>
                        <div className="modeSectionListTitleSub">Skycar Chase</div>
                        <div className="modeSectionListTitleSub">Vasir Combat</div>
                        <div className="modeSectionListTitleSub">Hagalaz: Storm the Base</div>
                        <div className="modeSectionListTitleSub">Final Confrontation</div>
                        <div className="modeSectionListTitle">Overlord</div>
                        <div className="modeSectionListTitle">Arrival</div>
                        <div className="modeSectionListTitle">Project Firewalker</div>
                        <div className="modeSectionListTitle">N7 Assignments</div>
                    </div>
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_freighter.png" />
                    </div>
                </div>
            </div>
            <a name="other"></a>
                <div className="modeSectionTitle">Additional Mechanics</div>
            <div className="modeSection">
                <div className="modeSectionIntro">
                    Use the pop bumpers to unlock class <b>powers</b> that can give you the edge on your missions.
                    Scan planets to collect minerals and <b>purchase upgrades</b> for persistent perks like longer
                    ball saves, extended timers, and faster awards. Build <b>reputation</b> through lane completions
                    that increase your end-of-ball bonuses.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_upgrade.png" />
                    </div>
                    Most importantly, <b>create a career profile</b> to save your progress through the game,
                    unlock achievements, and track personal best scores and times on every mode. For additional
                    challenge, <b>increase the difficulty</b> to Hardcore or Insanity and face tougher modes,
                    faster timers, and fewer ball saves.
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_profile.png" />
                    </div>
                </div>
                <div className="modeSectionListContainer">
                    <div className="modeImageContainer">
                        <img className="modeImage" src="/images/modes/cdx_harbinger.png" />
                    </div>
                    <div className="modeSectionList">
                        <div className="modeSectionListHeader">Featuring:</div>
                        <div className="modeSectionListTitle">Saved Career Profiles</div>
                        <div className="modeSectionListTitle">Powers</div>
                        <div className="modeSectionListTitleSub">Adrenaline Rush: pause all timers</div>
                        <div className="modeSectionListTitleSub">Biotic Charge: instantly hit any shot</div>
                        <div className="modeSectionListTitleSub">Combat Drone: instant multiball</div>
                        <div className="modeSectionListTitleSub">Singularity: move all shots closer</div>
                        <div className="modeSectionListTitleSub">Tactical Cloak: rotate lane shots</div>
                        <div className="modeSectionListTitleSub">Tech Armor: 10s ball save</div>
                        <div className="modeSectionListTitle">Planet Scanning</div>
                        <div className="modeSectionListTitle">Ship &amp; Armor Upgrades</div>
                        <div className="modeSectionListTitle">Reputation Bonus</div>
                        <div className="modeSectionListTitle">Harbinger Multiplier</div>
                        <div className="modeSectionListTitle">3 Difficulty Levels</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modes;