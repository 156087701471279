import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="Footer">
            <p className="footerBlock"><i>Mass Effect 2: the Pinball Game</i> is a fan project created by <span className="nowrap">Anthony van Winkle.</span> There is only one in existence and it is not for sale.</p>
            <p className="footerBlock"><i>Mass Effect 2</i> and all related properties &copy; BioWare Corp. and used under transformative noncommercial Fair Use.</p>
            <p className="footerBlock">Additional artwork from Jason Chan, Benjamin Huen, and Kim Rukiana. <i>"Mass Effect 123"</i> font by ASHPIKACHUFONTS. </p>
        </div>
    )
}

export default Footer;