import React from 'react';
import { Link } from 'react-router-dom';
import "./Navbar.css";

function Navbar() {
    return (
        <div className="Navbar">
            <div className="image screen-m">
                <img src="/images/me2pb-logo.png" alt="ME2 Pinball" />
            </div>
            <div className="navMenu">
                <Link className="navLink" to="/">Home</Link>
                <Link className="navLink" to="/modes"><span className="screen-s">Game </span>Modes</Link>
                <Link className="navLink" to="/machine"><span className="screen-s">the </span>Machine</Link>
                <Link className="navLink" to="/tour"><span className="screen-s">On </span>Tour</Link>
            </div>
        </div>
    )
}

export default Navbar;