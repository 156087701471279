import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Machine from './components/Machine';
import Modes from './components/Modes';
import ScrollToTop from './components/ScrollToTop';
import Tour from './components/Tour';
import './App.css';

function App() {
  return (
    <div className="App">
      <SimpleReactLightbox>
      <Router>
        <ScrollToTop />
        <div id="appContent" className="appContent">
          <Switch>
            <Route path="/modes">
              <Modes />
            </Route>
            <Route path="/machine">
              <Machine />
            </Route>
            <Route path="/tour">
              <Tour />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </div>
        <Navbar />
      </Router>
      </SimpleReactLightbox>
    </div>
  );
}

export default App;
