import React from 'react';
import Obfuscate from 'react-obfuscate';
import './Tour.css'

function Tour() {
    return (
        <div className="Tour">
            <div className="tourTitle">On Tour</div>
            <div className="tourContainer">
                <div className="tourDatesContainer">
                    < div className="tourYear">2020</div>
    
                    <div className="tourShow">Pinball Expo</div>
                    <div className="tourLocation">Chicago, IL</div>
                    <div className="tourDates cancelled">October 14 &ndash; 17</div>
                    <div className="tourCancelled">CANCELLED</div>
                    
                    <div className="tourShow">Portland Retro Gaming Expo</div>
                    <div className="tourLocation">Portland, OR</div>
                    <div className="tourDates cancelled">August 14 &ndash; 16</div>
                    <div className="tourCancelled">CANCELLED</div>
                    
                    <div className="tourShow">California Extreme</div>
                    <div className="tourLocation">Santa Clara, CA</div>
                    <div className="tourDates cancelled">July 25 &ndash; 26</div>
                    <div className="tourCancelled">CANCELLED</div>
                    
                    <div className="tourShow">Northwest Pinball &amp; Arcade Show</div>
                    <div className="tourLocation">Tacoma, WA</div>
                    <div className="tourDates cancelled">June 5 &ndash; 7</div>
                    <div className="tourCancelled">CANCELLED</div>
                    
                    <div className="tourShow">YEGPIN</div>
                    <div className="tourLocation">Edmonton, AB</div>
                    <div className="tourDates cancelled">May 1 &ndash; 3</div>
                    <div className="tourCancelled">CANCELLED</div>

                    <div className="tourYear">2019</div>
                        
                    <div className="tourShow">Portland Retro Gaming Expo</div>
                    <div className="tourLocation">Portland, OR</div>
                    <div className="tourDates">October 18 &ndash; 20</div>
                    
                    <div className="tourShow">Northwest Pinball &amp; Arcade Show</div>
                    <div className="tourLocation">Tacoma, WA</div>
                    <div className="tourDates">May 31 &ndash; June 2</div>
                </div>
                <div className="tourTextContainer">
                    <p>
                        Unfortunately all 2020 appearances have been cancelled
                        to reduce the spread of the coronavirus.
                    </p>
                    <p>
                        This website was created to share the game with the world, to delight
                        fans of Mass Effect, and to encourage pinball enthusiasts to take the
                        leap into homebrew pinball. Without taking the game on tour, this site
                        is the next best thing.
                    </p>
                    <p>
                        When it can be done safely, <i>Mass Effect 2: the Pinball Game</i> will
                        get back on the road. I can't wait to meet you all and let you experience
                        the thrill of Mass Effect 2 in pinball form.
                    </p>
                    <p>I especially look forward to attending YEGPIN in Edmonton, home of
                        BioWare and the Mass Effect team.
                    </p>
                    <br/>
                    <div className="tourYear">Contact</div>
                    <Obfuscate className="tourLink" email="anthony@masseffectpinball.com" />
                </div>
            </div>
        </div>
    )
}

export default Tour;