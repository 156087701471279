import React from 'react';
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";
import './Home.css';

const lightboxOptions = {
    buttons: {
        showDownloadButton: false,
    },
    caption: {
        showCaption: false,
    },
    thumbnails: {
        showThumbnails: false
    }
}

const galleryUrls = [
    "/images/gallery/ME2_01.png",
    "/images/gallery/ME2_02.png",
    "/images/gallery/ME2_03.png",
    "/images/gallery/ME2_04.png",
    "/images/gallery/ME2_05.png",
    "/images/gallery/ME2_06.png"
].map(url => (
    <a key={url} 
        href={url}
        className="homeGalleryItem" 
        // style={{ backgroundImage: "url('" + url.replace(".png", "_t.png") + "')"}}
        data-attribute="SRL"
     >
         <img src={url.replace(".png", "_t.png")} className="homeGalleryItemImage" />
    </a>
));

function Home() {
    return (
        <div className="Home">
            <div className="homeHero" name="">
                <div className="homeHeroTextContainer">
                    <div className="homeHeroTitle"><span className="quote">&quot;</span>&ensp;I'm Commander Shepard</div>
                    <div className="homeHeroSubtitle">and this is my favorite pinball game on the Citadel.&thinsp;<span className="quote">&quot;</span></div>
                </div>
            </div>
            <div className="homeIntro">
                <div className="homeIntroImageContainer">
                    <img className="homeIntroImage" src="/images/me2pb-logo-full.png" alt="ME2 Pinball Logo"/>
                </div>
                <div className="homeIntroP">Recruit a squad from the most skilled soldiers, biotics, and technicians across dozens of worlds.</div>
                <div className="homeIntroP">Survive the journey through the Omega 4 Relay and lead a suicide mission to the enemy's front door.</div>
                <div className="homeIntroP">Sacrifice everything, stand your ground, and save the galaxy.</div>
                <div className="homeIntroP"><i>Mass Effect 2: the Pinball Game</i> brings the challenge and excitement of BioWare's sci-fi epic to the arcade table, faithfully recreating the missions and quests of Commander Shepard in a brand-new pinball experience. From the slums of Omega to the towers of Illium and from the decks of the Normandy to the heart of the Collector base, the Mass Effect 2 Pinball Game is a loving homage for any Spectre who plays the silver ball.</div>
            </div>
                <SRLWrapper options={lightboxOptions}>
            <div className="homeGallery">
                    {galleryUrls}
            </div>
                </SRLWrapper>
            <div className="homeVideo">
                <div className="homeVideoContent">
                    <div className="homeVideoTitle">Learn More</div>
                    <div className="homeVideoSection homeVideoIframeContainer">
                        <iframe className="homeVideoIframe"
                             src="https://www.youtube.com/embed/bD-du970pEo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="homeVideoSection">
                        Read about <Link className="homeVideoLink" to="/modes">the game modes</Link> that recreate the complete Mass Effect 2 experience in pinball form.
                    </div>
                    <div className="homeVideoSection">
                        Learn about <Link className="homeVideoLink" to="/machine">the custom pinball machine</Link> that was built for Mass Effect 2 using open-source software and homebrew-ready hardware.
                    </div>
                    <div className="homeVideoSection">
                        <Link className="homeVideoLink" to="/tour">See the game in person</Link> or contact the creator for more information.
                    </div>
                </div> 
                <video controls loop className="homeVideoPlayer">
                    <source src="/video/masseffect-pinball-gameplay.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default Home;